<template>
	<div class="author-profile">
		<figure
			class="md:flex bg-blueGray-200 dark:bg-blueGray-700 rounded-xl p-8 md:p-0"
		>
			<img
				class="w-32 h-32 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
				:src="author.authorImageUrl"
				:alt="author.authorName + '\'s Photo'"
				width="384"
				height="512"
			/>
			<div
				class="pt-6 md:p-8 bg-blueGray-200 dark:bg-blueGray-700 text-center md:text-left space-y-4"
			>
				<blockquote>
					<p class="text-lg font-semibold bg-blueGray-200 dark:bg-blueGray-700">
						{{ author.authorIntro }}
					</p>
				</blockquote>
				<figcaption class="font-medium ">
					<div class="text-cyan-600 bg-blueGray-200 dark:bg-blueGray-700">
						{{ author.authorName }}
					</div>
					<div class="text-gray-500 bg-blueGray-200 dark:bg-blueGray-700">
						{{ author.authorPosition }}
					</div>
				</figcaption>
			</div>
		</figure>
	</div>
</template>

<script>
export default { name: "AuthorProfile", props: { author: Object } };
</script>

<style scoped></style>
